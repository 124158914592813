<template>
  <DefaultFrame
    :search="globalSearch"
    :menus="filteredMenus"
    :active-menu="activeMenu"
    :display-image="displayImage"
    search-placeholder="Search..."
    v-bind="$attrs"
    @update:search="updateGlobalSearch"
  >
    <template
      v-for="(slotName, index) in passedSlots"
      #[slotName]="slotData"
    >
      <slot
        v-if="slotName !== 'default'"
        v-bind="slotData"
        :key="slotName + index"
        :name="slotName"
      />

      <Suspense
        v-else-if="slotName === 'default'"
        :key="slotName + '-default-' + index"
      >
        <GlobalSearchTalentComponent
          v-if="showGlobalSearchComponent"
          :search="globalSearch"
          @close="closeGlobalSearch"
        />
        <slot v-else v-bind="slotData" :name="slotName" />
        <template #fallback>
          <Loader class="entry componentframescandidate loader" />
        </template>
      </Suspense>
    </template>
  </DefaultFrame>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  defineAsyncComponent,
  watch,
  defineComponent,
} from 'vue';
import { get, isEmpty, isEqual, isString, keys } from 'lodash-es';
import * as svgIcon from '@yzfe/svgicon';
import DefaultFrame from './Default.vue';
import { SideNavMenuType } from '@/types/logged';

const user = useUser();
await user.isModuleReady();

const slots = useSlots();
const passedSlots = computed(
  () => keys(slots) as unknown as string[],
);

const GlobalSearchTalentComponent = defineAsyncComponent({
  loader: () => import('@/components/globalsearch/TalentSearch.vue'),
});

const displayImage = ref('');
const route = useRoute();
const showGlobalSearchComponent = ref(false);
const globalSearch = ref('');
const router = useRouter();

const isShowingSubMenuFor = computed(
  () =>
    (name: string): boolean => {
      return activeMenu.value === name.toLowerCase();
    },
);

const expandedSubMenus = ref<string[]>([]);

const activeMenu = computed(() => {
  const menu = filteredMenus.value.find(({ subMenu }) => {
    if (subMenu && subMenu.length) {
      return !!subMenu.find((subMenuMenu) => {
        if (subMenuMenu.type === 'link') {
          // return (
          //   router.resolve(subMenuMenu.url).name === route.name ||
          //   subMenuMenu.isActive
          // );
        }

        if (subMenuMenu.type === 'button') {
          return subMenuMenu.isActive;
        }

        return false;
      });
    }

    return false;
  });

  if (menu) {
    return menu.name.toLowerCase();
  }

  return '';
});

const filteredMenuKey = ref(Date.now());
const filteredMenus = computed(() => {
  if (!filteredMenuKey.value) {
    return [];
  }

  const menus = ref<SideNavMenuType[]>([
    {
      name: 'Dashboard',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/dashboard.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: '/dashboard',
    },
    {
      name: 'My Jobs',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/job.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: '/jobs',
      isActive: computed(() =>
        [
          'job-jobTitle--jobUniqueId',
          'job-jobTitle--jobUniqueId-index',
          'jobs',
          'job-jobTitle--jobUniqueId-application',
        ].includes(String(route.name)),
      ),
    },
    {
      name: 'Assessments',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/histogram.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/assessments' },
    },
    {
      name: 'Interviews',
      icon: computedAsync(async () => {
        const svg = await import(
          '@/assets/icons/conversation.svg?icon'
        );

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/interviews' },
    },
    {
      name: 'Community',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/gt-icon.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/community' },
      isActive: computed(() => {
        const routePath = String(route.path);
        return (
          routePath === '/community' ||
          routePath.startsWith('/community/') ||
          route.matched.some((route) => route.path === '/community')
        );
      }),
    },
    {
      name: 'Marketplace',
      icon: computedAsync(async () => {
        const svg = await import(
          '@/assets/icons/marketplace/marketplace-icon.svg?icon'
        );

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: '/marketplace/courses',
      isActive: computed(() => {
        const routePath = String(route.path);

        return (
          routePath.startsWith('/marketplace') ||
          isShowingSubMenuFor.value('marketplace')
        );
      }),
      isSubMenuExpanded: computed(
        () =>
          expandedSubMenus.value.includes('marketplace') ||
          String(route.name || '').startsWith('marketplace.'),
      ),
      canIsolateSubMenu: ref(false),
      toggleExpandSubMenu: () => {
        const index = expandedSubMenus.value.findIndex(
          (name) => name === 'marketplace',
        );

        if (index > -1) {
          expandedSubMenus.value.splice(index, 1);
        } else {
          expandedSubMenus.value.push('marketplace');
        }
      },
      subMenu: [
        {
          name: 'Courses',
          icon: computedAsync(async () => {
            const svg = await import(
              '@/assets/icons/marketplace/courses.svg?icon'
            );

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          type: 'link',
          url: '/marketplace/courses',
          isActive: computed(() =>
            ['/marketplace/courses'].includes(String(route.path)),
          ),
        },
        {
          name: 'Purchases',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import(
              '@/assets/icons/marketplace/purchases-icon.svg?icon'
            );

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: '/marketplace/purchases',
          isActive: computed(() =>
            ['/marketplace/purchases'].includes(String(route.name)),
          ),
        },
      ],
    },
    {
      name: 'Wallet',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/wallet.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/finance/wallet' },
    },
    {
      name: 'Profile',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/account.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/profile/view' },
      isActive: computed(() =>
        ['/profile/view', '/profile/edit'].includes(
          String(route.path),
        ),
      ),
    },
    {
      name: 'Settings',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/settings.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/talent-settings' },
    },
  ]);

  if (inProduction.value) {
    const index = menus.value.findIndex(
      (menu) => menu.name.toLowerCase() === 'marketplace',
    );

    if (index >= 0) {
      menus.value.splice(index, 1);
    }
  }

  return menus.value;
});

watch(
  () => user.profileData,
  (profileData, prev) => {
    if (
      profileData &&
      !isEmpty(profileData) &&
      !isEqual(profileData, prev)
    ) {
      filteredMenuKey.value = Date.now();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

const closeGlobalSearch = () => {
  showGlobalSearchComponent.value = false;
};

watch(
  () => router.currentRoute?.value,
  () => {
    closeGlobalSearch();
  },
);

const updateGlobalSearch = (search: string) => {
  if (search && isString(search)) {
    globalSearch.value = search;
    showGlobalSearchComponent.value = true;
  }
};
</script>

<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
});
</script>

<style lang="scss" scoped>
.entry.componentframescandidate.loader {
  height: calc(100vh - 500px);
  width: 100%;
}
</style>
