<template>
  <AccountVerification v-if="hasSentToken" :has-sent-token="true" />
  <div v-else class="component entry framesauthunlogged">
    <div class="wrapper">
      <a
        :href="homepageLink"
        class="logo"
        @click.prevent="goToHomepage"
      >
        <icon
          class="icon"
          data="@/assets/icons/logo.svg?icon"
          original
        />
      </a>
      <img
        src="@/assets/images/svgs/auth/blob.svg?url"
        alt="Goodtalent"
        class="auth-blob"
      />
      <div class="design">
        <icon
          class="image"
          data="@/assets/images/svgs/auth/main.svg?icon"
          original
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';

const AccountVerification = defineAsyncComponent({
  loader: () => import('@/components/AccountVerification.vue'),
});

const route = useRoute();
const user = useUser();
await user.isModuleReady();

const hasSentToken = computed(
  () =>
    user.mode === 'token-sent' && route.name !== 'forgot-password',
);

const homepageLink = user.generalUrlFor('/');
const goToHomepage = () => user.goToGeneralUrlFor('/');
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;
.component.entry.framesauthunlogged {
  :deep() {
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    > .wrapper {
      padding: 64px 45px 64px 45px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      align-items: stretch;
      min-height: 100vh;
      max-width: viewport.$max-width;
      width: 100%;

      @include breakpoint.respond-below(msl) {
        padding: 40px 25px 40px 25px;
      }

      @include breakpoint.respond-below(sm) {
        justify-content: center;
        flex-wrap: wrap;
      }

      > * {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: center;
        padding: 20px 35px;
        height: 100%;

        @include breakpoint.respond-below(xi) {
          padding: 20px 0;
        }
      }

      > .logo {
        display: inline-flex;
        position: absolute;
        top: 15px;
        left: 20px;
        width: 200px;
        height: auto;
        > .icon {
          width: 100%;
          height: 45px;
        }

        @include breakpoint.respond-below(sm) {
          position: static;
          left: initial;
          right: initial;
          display: flex;
          justify-content: center;
          z-index: 2;
        }
      }

      > .auth-blob {
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        padding: 0;

        @include breakpoint.respond-below(msl) {
          width: 80px;
        }
      }

      > .design {
        width: 50%;
        height: 100%;
        justify-content: center;
        align-items: center;

        @include breakpoint.respond-below(sm) {
          display: none;
        }
        > .image {
          width: 100%;
          height: 95%;
        }
      }
    }
  }
}
</style>
