<template>
  <div class="component entry framesunlogged">
    <LazyOverlaysSubscriptionPlatformBasedRecruitmentForm
      v-if="showPlatFormmBasedRecruitmentForm"
      @close="showPlatFormmBasedRecruitmentForm = false"
    />
    <header>
      <a
        :href="homepageLink"
        class="logo"
        @click.prevent="goToHomepage"
      >
        <icon
          class="icon"
          data="@/assets/icons/logo.svg?icon"
          original
        />
      </a>

      <button ref="showSideNavToggle" class="mobile-toggle">
        <icon
          ref="toggle-button"
          class="icon bars"
          data="@/assets/icons/bars.svg?icon"
          @click="toggleSideNav"
        />
      </button>

      <div v-if="grid.lg" class="navigation-links">
        <div class="buttons">
          <nuxt-link
            :to="{ name: 'find-a-role' }"
            class="link"
            exact-active-class="exact-active"
          >
            Find a Role
          </nuxt-link>
          <nuxt-link
            :to="{ name: 'pricing' }"
            class="link"
            exact-active-class="exact-active"
          >
            Pricing
          </nuxt-link>
          <nuxt-link
            :to="{ name: 'jobs' }"
            class="link"
            exact-active-class="exact-active"
            :class="{ 'exact-active': isJobsActive }"
          >
            Job Openings
          </nuxt-link>
          <button
            class="gtco-support link"
            :class="{
              'exact-active': showPlatFormmBasedRecruitmentForm,
            }"
            @click.prevent="showPlatFormmBasedRecruitmentForm = true"
          >
            Hiring Support
          </button>
          <nuxt-link
            to="https://blog.goodtalent.io/b/"
            class="link"
            target="_blank"
          >
            Blog
          </nuxt-link>
        </div>
      </div>

      <div v-if="grid.lg" class="buttons">
        <template v-if="!isLogged">
          <nuxt-link :to="{ name: 'login' }" class="link login">
            Login
          </nuxt-link>
          <!-- <button
            class="link refer-earn"
            @click="getStarted('referrer')"
          >
            Refer and Earn
          </button> -->
        </template>
        <template v-else>
          <a
            :href="dashboardLink"
            class="link dashboard"
            @click.prevent="goToDashboard"
          >
            Dashboard
          </a>
          <button class="link logout" @click.prevent="logout">
            Logout
          </button>
        </template>
      </div>
    </header>

    <suspense>
      <template #default>
        <LazyNuxtPage />
      </template>
      <template #fallback>
        <Loader class="loader" size="large" />
      </template>
    </suspense>

    <div class="footer">
      <div class="footer-top">
        <div class="footer-top-box-wrapper">
          <div class="footer-top-box footer-box-one">
            <v-lazy-image
              class="logo"
              src="@/assets/icons/homePricing/logo.svg?url"
              alt="Goodtalent Logo"
            />
            <p>
              We are democratizing the way companies hire, screen and
              pay top technology talents
            </p>
          </div>
          <div class="footer-top-box footer-box-two">
            <h3>Company</h3>
            <ul>
              <li>
                <nuxt-link :to="{ name: 'register' }">
                  Find a Talent
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'pricing' }">
                  Pricing
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'jobs' }">
                  Job Openings
                </nuxt-link>
              </li>
              <li>
                <button
                  class="footer-link"
                  @click.prevent="
                    showPlatFormmBasedRecruitmentForm = true
                  "
                >
                  Hiring Support
                </button>
              </li>
              <li>
                <a
                  href="https://blog.goodtalent.io/b/"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://gtco.grovehr.com/careers"
                  target="_blank"
                >
                  Careers @ Goodtalent
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-top-box footer-box-three">
            <h3>Social</h3>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/goodtalentcorp"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/goodtalentcorp#"
                  target="_blank"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-top-box footer-box-four">
            <h3>Contact</h3>
            <ul>
              <li>
                <span>
                  Suite S4 & S5 Park House, Church Place, Swindon, SN1
                  5ED, United Kingdom
                </span>
              </li>
              <li>
                <a href="tel:+441793232101">T: +441793290599</a>
              </li>
              <li>
                <a href="mailto:support@goodtalent.io">
                  E: support@goodtalent.io
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div>
          <p>Registered in the United Kingdom: 12941815</p>
          <p>
            Registered in the United States (Delaware) : FN 7099553;
            SR 20223840419
          </p>
          <p>ICO registration reference ZA931336</p>
        </div>
      </div>
      <div class="footer-copyright">
        <div>
          <p>
            Copyright 2010 - 2023 Goodtalent Corporation Ltd;
            Goodtalent, Inc
          </p>
          <div>
            <nuxt-link :to="{ name: 'privacy' }" target="_blank">
              Privacy policy
            </nuxt-link>
            <nuxt-link :to="{ name: 'terms' }" target="_blank">
              Website terms of use
            </nuxt-link>
            <nuxt-link
              :to="{ name: 'acceptable-use' }"
              target="_blank"
            >
              Acceptable Use Policy
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showSideNav"
      ref="sideNavEl"
      class="nav"
      @click="hideSideNav"
    >
      <a
        :href="homepageLink"
        class="logo"
        @click.prevent="goToHomepage"
      >
        <icon
          class="icon"
          data="@/assets/icons/logo.svg?icon"
          original
        />
      </a>
      <nuxt-link
        :to="{ name: 'find-a-role' }"
        class="link"
        exact-active-class="exact-active"
      >
        Find a Role
      </nuxt-link>
      <nuxt-link
        :to="{ name: 'pricing' }"
        class="link"
        exact-active-class="exact-active"
      >
        Pricing
      </nuxt-link>
      <nuxt-link
        :to="{ name: 'jobs' }"
        class="link"
        exact-active-class="exact-active"
        :class="{ 'exact-active': isJobsActive }"
      >
        Job Openings
      </nuxt-link>
      <button
        class="gtco-support link"
        :class="{ 'exact-active': showPlatFormmBasedRecruitmentForm }"
        @click.prevent="showPlatFormmBasedRecruitmentForm = true"
      >
        Hiring Support
      </button>

      <a
        href="https://blog.goodtalent.io/b/"
        class="link"
        target="_blank"
      >
        Blog
      </a>

      <div class="user-links">
        <template v-if="!isLogged">
          <nuxt-link :to="{ name: 'login' }" class="link login">
            Login
          </nuxt-link>
        </template>

        <template v-else>
          <a
            :href="dashboardLink"
            class="link dashboard"
            @click.prevent="goToDashboard"
          >
            Dashboard
          </a>
          <button class="link logout" @click.prevent="logout">
            Logout
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useGrid } from 'vue-screen';
import { onClickOutside } from '@vueuse/core';
import { get } from 'lodash-es';

const user = useUser();
await user.isModuleReady();

const route = useRoute();

const homepageLink = computed(() => user.generalUrlFor('/'));
const goToHomepage = () => user.goToGeneralUrlFor('/');
const dashboardLink = computed(() =>
  user.generalUrlFor('/dashboard'),
);
const goToDashboard = () => user.goToGeneralUrlFor('/dashboard');
const isLogged = computed(() => user.isLogged);

const logout = () => user.logout();

const grid = useGrid(user.breakpoints);

const toggleSideNav = () => {
  user.setState({
    showSideNav: !showSideNav.value,
  });
};

const hideSideNav = () => {
  user.setState({
    showSideNav: false,
  });
};

const showSideNav = computed(() =>
  !grid.lg ? user.showSideNav : false,
);

const isJobsActive = computed(() =>
  [
    'jobs',
    'job-jobTitle--jobUniqueId',
    'job-jobTitle--jobUniqueId-index',
    'job-jobTitle--jobUniqueId-applications',
  ].includes(String(route.name)),
);

// const isMarketPlaceActive = computed(() =>
//   String(route.path).startsWith('/marketplace'),
// );

watch(
  () => route.fullPath,
  () => {
    hideSideNav();
  },
);

const sideNavEl = ref<HTMLElement | undefined>(undefined);
const showSideNavToggle = ref();

onClickOutside(sideNavEl, (e) => {
  if (!grid.lg) {
    const target = e.target as Node;
    const button = get(
      showSideNavToggle,
      'value.$el',
    ) as HTMLElement | null;

    if (button) {
      if (button === target || button.contains(target)) return;
    }

    user.setState({
      showSideNav: false,
    });
  }
});

const showPlatFormmBasedRecruitmentForm = ref(false);
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;

@font-face {
  font-family: 'Circular Std';
  src: url('../../assets/fonts/circularStd/CircularStd-Medium.ttf')
    format('truetype');
}

$font-home: 'Circular Std', sans-serif;

.component.entry.framesunlogged {
  :deep() {
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    > header,
    > footer {
      width: 100%;
      display: flex;
      padding: 15px 40px;
      max-width: viewport.$max-width;

      @include breakpoint.respond-below(lg) {
        padding: 15px 5%;
      }

      @include breakpoint.respond-below(md) {
        padding: 26px 5px;
        &:not(footer) {
          margin-top: 7px;
          padding: 26px 20px;
        }
      }
    }

    ul {
      list-style: none;
    }

    a {
      text-decoration: none;
    }
    .footer-bottom p {
      margin: 0;
    }

    > header {
      display: flex;
      width: 100%;
      z-index: 1;
      margin-top: 5px;
      // margin: 5px 20px 10px 0;
      justify-content: space-between;
      align-items: center;

      > .logo {
        display: inline-flex;
        width: 200px;
        height: auto;
        align-items: center;

        > .icon {
          width: 100%;
          height: 36px;
        }
      }

      > .mobile-toggle {
        display: none;
        margin: 0 0 0 auto;
        background-color: initial;
        padding: 0;
        outline: initial;
        border: initial;
        cursor: pointer;

        @include breakpoint.respond-below(lg) {
          display: inline-flex;
        }

        > .icon.bars {
          width: 25px;
          height: 25px;
          transform: scaleY(1.3);
          margin: 0;
          cursor: pointer;
        }
      }

      > .navigation-links {
        margin-left: -60px;
        display: flex;
        align-items: center;
      }

      > .navigation-links > .buttons,
      > .buttons {
        display: flex;
        align-items: center;

        a {
          display: inline-block;
          cursor: pointer;
          color: #281103;
        }

        > .link.login {
          background-color: #1565c0;
          color: #ffffff;
        }

        > .link {
          outline: initial;
          font-size: 16px;
          color: #281103;
          background-color: color.$white-1;
          transition: all 0.3s ease-in-out;
          border-radius: 7px;
          border: none;
          padding: 13px 30px;
          cursor: pointer;
          text-decoration: initial;
          white-space: nowrap;
          font-family: $font-home;

          @include breakpoint.respond-below(lg) {
            padding: 13px 15px;

            &.register,
            &.login,
            &.dashboard,
            &.logout {
              // background-color: color.$brown-2;
              background-color: #281103;
              color: color.$white-1;
              margin: 0 0 0 auto;
            }
          }

          &.logout {
            margin: 0 0 0 10px;
          }

          &:not(:first-child):not(.login):not(.dashboard) {
            margin: 0 0 0 10px;
          }

          &:hover,
          &.exact-active {
            // background-color: color.$brown-2;
            background-color: color.$blue-2;
            color: color.$white-1;
          }
        }
      }
    }

    > footer {
      display: flex;
      background-color: color.$white-1;
      width: 100%;
      justify-content: center;
      margin: auto 0 0 0;
      align-items: flex-start;
      align-content: flex-start;
      flex-direction: column;
      padding-top: 30px;
      border-top: 1px solid color.$light-brown-2;
      margin-top: 40px;

      @include breakpoint.respond-below(md) {
        justify-content: center;
      }

      > .data {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        @include breakpoint.respond-below(md) {
          flex-wrap: wrap;
          align-content: flex-start;
        }

        > .info,
        > .menu,
        > .details {
          > .title {
            display: inline-flex;
            width: 100%;
            color: color.$brown-2;
            font-size: 20px;
            margin: 0;
            margin-bottom: 10px;

            @include breakpoint.respond-below(lg) {
              justify-content: center;
              margin-bottom: initial;
            }
          }
        }

        > .info {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          width: 40%;

          @include breakpoint.respond-below(md) {
            width: 100%;
            align-items: center;
          }

          > .logo {
            width: 150px;
            height: 40px;

            @include breakpoint.respond-below(md) {
              margin-left: auto;
              margin-right: auto;
            }
          }

          > .description {
            display: inline-flex;
            width: 95%;
            justify-content: flex-start;
            text-align: left;
            font-size: 14px;
            line-height: 35px;
            margin: 0;
            font-family: 'LatoLight', Tahoma, Geneva, sans-serif;

            @include breakpoint.respond-below(md) {
              text-align: center;
              justify-content: center;
              margin: 20px 0 0 0;
            }
          }

          > .socials {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin: 20px 0 0 0;

            @include breakpoint.respond-below(md) {
              justify-content: center;
              margin-top: 30px;
            }

            > .social {
              text-decoration: initial;
              width: 25px;
              height: 25px;
              display: inline-flex;
              color: color.$black-1;
              transition: all 0.3s ease-in-out;

              &:not(:first-child) {
                margin: 0 0 0 20px;
              }

              &:hover {
                color: color.$brown-2;
              }

              > .icon {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        > .menu {
          display: flex;
          flex-direction: column;
          margin: 0 200px 0 auto;

          @include breakpoint.respond-below(md) {
            width: 100%;
            margin: 30px 0 0 0;
          }

          > .item {
            display: inline-flex;
            margin: 20px 0 0 0;
            font-size: 14px;
            font-family: 'LatoLight', Tahoma, Geneva, sans-serif;
            color: color.$black-1;
            width: 100%;
            text-decoration: initial;

            @include breakpoint.respond-below(lg) {
              justify-content: center;
            }
          }
        }

        > .details {
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 15px 0 15px;

          @include breakpoint.respond-below(md) {
            width: 100%;
            align-items: flex-start;
            padding: 0 15%;
            margin-top: 30px;
          }

          > .data {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0;

            &:not(:first-child) {
              margin: 20px 0 0 0;
            }

            > .icon {
              width: 30px;
              height: 30px;
              color: color.$brown-1;
            }

            a {
              color: color.$brown-2;
            }

            > .text {
              display: inline-flex;
              margin: 0 0 0 15px;
              font-size: 14px;
              font-family: 'LatoLight', Tahoma, Geneva, sans-serif;
            }
          }
        }
      }

      > .legal {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin: 30px 0 0 0;
        border: none;
        border-top: 1px solid color.$light-brown-2;
        padding: 30px 0 0 0;

        @include breakpoint.respond-below(md) {
          margin: 40px 0 0 0;
        }

        > .text {
          display: flex;
          width: 100%;
          font-size: 11px;
          font-family: 'LatoLight', Tahoma, Geneva, sans-serif;

          @include breakpoint.respond-below(md) {
            justify-content: center;
            text-align: center;
          }

          &:not(:first-child) {
            margin: 10px 0 0 0;

            @include breakpoint.respond-below(md) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    > .nav {
      display: none;
      max-width: 70%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2000;
      box-shadow: 2px 2px 8px 0 color.$ash-1;
      width: auto;
      flex-direction: column;
      align-items: center;
      padding: 30px 20px 0;
      min-height: 100vh;
      background-color: color.$white-1;
      transition: all 0.3s ease-in-out;

      @include breakpoint.respond-below(lg) {
        display: flex;
      }

      > * {
        display: flex;
        width: 180px;
        flex-wrap: nowrap;
      }

      > .logo {
        height: auto;

        > .icon {
          width: 100%;
          height: auto;
        }
      }

      > .link,
      .user-links {
        display: inline-flex;
        outline: initial;
        border-radius: 7px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        font-size: 16px;
        white-space: nowrap;
        text-decoration: initial;
      }

      .link {
        padding: 13px 30px;
        width: 100%;
        color: #281103;
        background-color: color.$white-1;
        border: none;

        @include breakpoint.respond-below(lg) {
          padding: 13px 15px;
        }

        &.register,
        &.dashboard {
          background-color: #281103;
          color: color.$white-1;
          margin: 0 0 0 auto;
        }

        &:not(:first-child) {
          margin: 20px 0 0 0;
        }

        &:hover,
        &.exact-active {
          background-color: color.$blue-2;
          color: color.$white-1;
        }

        &.refer-earn {
          outline: initial;
          // color: color.$brown-2;
          color: white;
          background-color: #1565c0;
          transition: all 0.3s ease-in-out;
          border-radius: 7px;
          border: none;
          padding: 13px 30px;
          cursor: pointer;
          font-size: 16px;
          text-decoration: initial;
          white-space: nowrap;
          display: inline-block;

          @include breakpoint.respond-below(lg) {
            padding: 13px 15px;
          }

          &:hover {
            background-color: #1565c0;
            color: white;
          }
        }
      }

      .user-links {
        padding: 0 30px;
        margin-left: 30px;
        background-color: #1565c0;
        margin-top: 100px;
        max-width: max-content;
        align-self: flex-start;

        @include breakpoint.respond-below(lg) {
          margin-left: 15px;
        }
        .link {
          background-color: transparent;
          color: #ffffff;
        }
      }
    }
  }

  .loader {
    height: 100vh;
    width: 100%;
  }
}

// Footer
.footer {
  border-top: 0.8px solid #90caf9;
  width: 100%;
}

.footer-top,
.footer-bottom,
.footer-copyright {
  &:not(.footer-copyright) {
    border-bottom: 0.8px solid #90caf9;
  }
  &:not(.footer-bottom) {
    > div {
      padding: 20px 45px;
    }
  }

  @include breakpoint.respond-below(md) {
    > div {
      padding: 35px 32px;
    }
  }

  button.footer-link {
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.0015em;

    @include breakpoint.respond-below(sm) {
      font-size: 14px;
    }
  }
}

.footer-bottom > div {
  padding: 35px 45px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include breakpoint.respond-below(md) {
    padding: 35px;
  }
}

.footer-copyright > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include breakpoint.respond-below(md) {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    text-align: center;
    p {
      color: #212121;
      font-weight: 500;
      line-height: 140%;
    }
    a {
      color: #1565c0;
      margin-right: 25px;
      font-weight: 500;
      line-height: 32px;
    }
  }
}

.footer-top > .footer-top-box-wrapper {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 10%;
}

.footer .footer-top > .footer-top-box-wrapper .logo {
  margin-bottom: 25px;
}

.footer-top-box {
  margin-top: 30px;
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    padding-left: 0;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
  }

  &.footer-box-four {
    span,
    a {
      font-size: 14px;
      color: #424242;
    }
  }

  h3 {
    font-weight: 600;
    color: #281103;
  }

  @include breakpoint.respond-below(md) {
    &.footer-box-one {
      margin-bottom: 44px;
      max-width: 70%;
    }
    h3 {
      font-weight: 600;
      color: #281103;
    }
  }
}

.footer-box-one p {
  margin: 0;
}

.footer-top-box ul li {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  line-height: 1;
  padding: 8px 0;
}

.footer-top-box a,
.footer-top-box p,
.footer-top-box button,
.footer-top-box span,
.footer-bottom p {
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.0015em;
  color: #424242;
  font-size: 14px;
  &:is(.footer-bottom p) {
    font-size: 14px;
  }
}

.footer-copyright p,
.footer-copyright a {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;

  &:is(a) {
    letter-spacing: 0.0015em;
    margin-right: 50px;
    color: #1565c0;
  }
}

.footer-social-icons {
  column-gap: 12px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.footer-social-icons img {
  cursor: pointer;
}

@include breakpoint.respond-below(lg) {
  .footer-top > .footer-top-box-wrapper {
    display: block;
  }

  .footer-box-one p {
    font-size: 14px;
    line-height: 1.5;
  }

  .footer-top-box ul {
    row-gap: 15px;
  }

  .footer-top-box ul:first-child {
    margin-top: 35px;
  }

  .footer-top-box.footer-box-three ul {
    margin-bottom: 0;
  }

  .footer-top-box.footer-box-four ul {
    margin: 0;
    margin-top: 15px;
    span,
    a {
      font-size: 14px;
    }
  }

  .footer-copyright p,
  .footer-copyright a {
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.1px;
  }
  .footer-copyright a {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-box-two a,
  .footer-box-three a {
    font-size: 14px;
  }

  .footer-box-one p {
    margin-top: 0;
  }

  .footer-box-four ul {
    margin-top: 15px;
  }

  .footer-box-five ul li:first-child {
    font-size: 18px;
    line-height: 1.3;
  }

  .footer-copyright p,
  .footer-copyright a {
    font-size: 12px;
  }

  .footer-box-five ul li p {
    font-size: 14px;
  }

  .footer-box-five p a {
    font-size: 14px !important;
  }
}
</style>
