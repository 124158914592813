<template>
  <FramesDefault
    ref="defaultFrameRef"
    :menus="permittedMenus"
    :wallet-balance="mainBalance"
    @get-viewport-dimensions="
      (viewport, container, content) => (
        (referralViewPortWidth = viewport),
        (referralContentWidth = content)
      )
    "
    @update:search="updateGlobalSearch"
  >
    <template #default>
      <Suspense>
        <template #default>
          <Suspense>
            <template #default>
              <GlobalSearchTalentComponent
                v-if="showGlobalSearchComponent"
                :search="globalSearch"
                @close="closeGlobalSearch"
              />
              <slot v-else></slot>
            </template>
            <template #fallback>
              <Loader class="entry componentframesreferral loader" />
            </template>
          </Suspense>
        </template>
        <template #fallback>
          <Loader class="entry componentframesreferral loader" />
        </template>
      </Suspense>
    </template>

    <template v-for="name in otherSlots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </FramesDefault>
</template>

<script setup lang="ts">
import { get, isString, keys, without } from 'lodash-es';
import * as svgIcon from '@yzfe/svgicon';
import { SideNavMenuType } from '~/types/logged';
import { WalletBalanceTypes } from '~/types/finance';
const GlobalSearchTalentComponent = defineAsyncComponent({
  loader: () => import('@/components/globalsearch/TalentSearch.vue'),
});

const referralContentWidth = ref<string>('100%');
const referralViewPortWidth = ref<string>('100%');

defineExpose({
  referralContentWidth,
  referralViewPortWidth,
});

const showGlobalSearchComponent = ref(false);
const globalSearch = ref('');
const slots = useSlots();

const closeGlobalSearch = () => {
  showGlobalSearchComponent.value = false;
};
const router = useRouter();

watch(
  () => router.currentRoute?.value,
  () => {
    closeGlobalSearch();
  },
);

const updateGlobalSearch = (search: string) => {
  if (search && isString(search)) {
    globalSearch.value = search;
    showGlobalSearchComponent.value = true;
  }
};

const user = useUser();
await user.isModuleReady();
const route = useRoute();
const otherSlots = computed(() => {
  const list = slots;
  return without(keys(list), 'default') as string[];
});

const allMenus = ref<SideNavMenuType[]>([
  {
    name: 'Dashboard',
    icon: computedAsync(async () => {
      const svg = await import('@/assets/icons/dashboard.svg?icon');

      if (svg) {
        return get(svg, 'default', '') as svgIcon.Icon;
      }

      return '';
    }, ''),
    type: 'link',
    url: { name: 'referral-dashboard' },
  },
  {
    name: 'Job Board',
    icon: computedAsync(async () => {
      const svg = await import('@/assets/icons/job.svg?icon');

      if (svg) {
        return get(svg, 'default', '') as svgIcon.Icon;
      }

      return '';
    }, ''),
    type: 'link',
    url: { name: 'referral-jobs' },
    isActive: computed(() =>
      [
        'referral-job-jobTitle--jobUniqueId',
        'referral-job-jobTitle--jobUniqueId-index',
        'referral-job-edit',
        'referral-job',
        'referral-job-create',
        'referral-job-jobTitle--jobUniqueId-application',
      ].includes(String(route.name)),
    ),
  },
  {
    name: 'Your Candidates',
    icon: computedAsync(async () => {
      const svg = await import('@/assets/icons/referral.svg?icon');

      if (svg) {
        return get(svg, 'default', '') as svgIcon.Icon;
      }

      return '';
    }, ''),
    type: 'link',
    url: { name: 'referral-candidates' },
  },
  {
    name: 'Wallet',
    icon: computedAsync(async () => {
      const svg = await import('@/assets/icons/wallet.svg?icon');

      if (svg) {
        return get(svg, 'default', '') as svgIcon.Icon;
      }

      return '';
    }, ''),
    type: 'link',
    url: { name: 'finance-wallet' },
  },
  {
    name: 'Profile',
    icon: computedAsync(async () => {
      const svg = await import('@/assets/icons/account.svg?icon');

      if (svg) {
        return get(svg, 'default', '') as svgIcon.Icon;
      }

      return '';
    }, ''),
    type: 'link',
    url: { name: 'profile-view' },
  },
]);
const permittedMenus = computed<SideNavMenuType[]>(() => {
  return allMenus.value.filter(() => {
    return user.profileType === 'referrer'; // no perm logic yet, so simple filter
  });
});

const mainBalance = computed(
  () =>
    formatAmount({
      type: '',
      amount: user.walletBalance(WalletBalanceTypes.MAIN) as number,
      currency: 'USD',
      options: {
        minimumFractionDigits: 2,
      },
    }).value,
);
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;
@use 'sass:color' as sasscolor;

.entry.componentframesreferral.loader {
  height: calc(100vh - 500px);
  width: 100%;
}
:deep() {
  .talentwallettab.entry {
    @include breakpoint.respond-below(xl) {
      max-width: v-bind(referralContentWidth) !important;
    }
    @include breakpoint.respond-below(lg) {
      max-width: v-bind(referralViewPortWidth) !important;
    }
  }
}
</style>
