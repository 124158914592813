<template>
  <div class="component framesdefault entry">
    <Loader v-if="!isPageReady" size="large" class="loader" />
    <div
      v-show="isPageReady"
      :key="refreshKey"
      ref="sidenav"
      class="sidenav"
      :class="[
        ...sideNavAnimationClasses,
        { absolute: !grid.lg, show: showSideNav },
        user.userType,
      ]"
    >
      <slot name="sidenav">
        <slot name="sidenav-logo">
          <nuxt-link class="logo" to="/dashboard">
            <icon
              class="icon"
              data="@/assets/icons/logo.svg?icon"
              original
            />
          </nuxt-link>
        </slot>
        <div
          class="menu"
          :class="user.userType"
          @click="closeNavigation"
        >
          <slot name="sidenav-menus">
            <template v-if="menus.length">
              <div
                v-for="menu in menus"
                :key="menu.name"
                class="menu-item"
                active-class="active"
              >
                <nuxt-link
                  class="link-item"
                  active-class="active"
                  :class="{ active: menu.isActive }"
                  :to="menu.type === 'link' ? menu.url : ''"
                  @click="handleClickOnSubMenuParent($event, menu)"
                >
                  <icon
                    v-if="menu.icon"
                    class="icon"
                    :data="menu.icon"
                  />
                  <span class="name">{{ menu.name }}</span>
                </nuxt-link>
                <div
                  v-if="
                    isInlineSubMenuFor(menu.name) &&
                    menu.isSubMenuExpanded
                  "
                  class="sub-menu"
                >
                  <template
                    v-if="subMenuData.list && subMenuData.list.length"
                  >
                    <nuxt-link
                      v-for="subMenu in subMenuData.list"
                      :key="subMenu.name"
                      class="link-item"
                      active-class="active"
                      :class="{ active: subMenu.isActive }"
                      :to="subMenu.type === 'link' ? subMenu.url : ''"
                    >
                      <icon
                        v-if="subMenu.icon"
                        class="icon"
                        :data="subMenu.icon"
                      />
                      <span class="name">{{ subMenu.name }}</span>
                    </nuxt-link>
                  </template>
                </div>
              </div>
            </template>
          </slot>
          <slot name="after-menu">
            <nuxt-link
              v-if="user.userType !== 'referrer'"
              active-class="active"
              to="/messages"
              class="link-item"
            >
              <icon
                class="icon"
                data="@/assets/icons/message.svg?icon"
              />
              <span class="name">Instant Messaging</span>
            </nuxt-link>

            <nuxt-link class="link-item" :to="{ name: 'profiles' }">
              <icon
                class="icon"
                data="@/assets/icons/swap_vertical.svg?icon"
              />
              <span class="name">Switch Profile</span>
            </nuxt-link>
            <button class="link-item logout" @click.prevent="logout">
              <icon
                class="icon"
                data="@/assets/icons/logout.svg?icon"
              />
              <span class="name">logout</span>
            </button>
          </slot>
        </div>
      </slot>
    </div>
    <div
      v-show="isPageReady"
      class="content-container"
      :class="[
        ...animationClasses,
        { mobile: !grid.lg },
        user.userType,
      ]"
    >
      <div v-if="grid.sm" class="topnav" :class="[user.userType]">
        <slot name="topnav">
          <icon
            v-if="!grid.lg"
            ref="toggle-button"
            class="icon bars"
            data="@/assets/icons/bars.svg?icon"
            @click="toggleSideNav"
          />
          <span
            v-if="user.userType === 'referrer'"
            class="wallet-preview"
          >
            <slot name="wallet">
              Wallet Balance:
              <NuxtLink
                to="/finance/wallet"
                class="wallet gt-text-secondary-2"
              >
                {{ props.walletBalance }}
              </NuxtLink>
            </slot>
          </span>
          <span v-if="canSearch" class="search">
            <slot name="search">
              <span class="group">
                <span
                  v-on-click-outside="() => (searchFocused = false)"
                  class="input-icon"
                  :class="searchFocused ? 'focus' : ''"
                  @click="searchFocused = true"
                >
                  <input
                    v-model="localSearchRef"
                    type="text"
                    name="search"
                    :placeholder="searchPlaceholder"
                    @change="
                      emit(
                        'search-change',
                        getChangeEventValue($event),
                      )
                    "
                    @keyup.enter="handleSearch"
                  />
                  <icon
                    class="icon"
                    data="@/assets/icons/microscope.svg?icon"
                    @click.prevent="handleSearch"
                  />
                </span>
              </span>
            </slot>
          </span>
          <span class="actions">
            <slot name="actions">
              <button
                v-if="showCommunityMessaging"
                class="messages"
                title="Instant messaging | conversations"
                :class="{
                  'has-unread': unreadCommunityMessagingCount > 0,
                }"
                @click.stop.prevent="
                  toggleCommunityMessagingDisplay()
                "
              >
                <icon
                  class="icon"
                  data="@/assets/icons/message.svg?icon"
                />
                <span
                  v-if="unreadCommunityMessagingCount > 0"
                  class="unread-count"
                >
                  {{ unreadCommunityMessagingCount }}
                </span>
              </button>
              <button class="notifications" :class="user.userType">
                <icon
                  class="icon"
                  data="@/assets/icons/bell.svg?icon"
                />
              </button>
              <span v-if="user.profileImageUrl" class="display-image">
                <slot name="display-image">
                  <img :src="user.profileImageUrl" alt="" />
                </slot>
              </span>
              <nuxt-link
                class="switch-account"
                title="Switch Profile"
                :to="{ name: 'profiles' }"
              >
                <template v-if="user.userType !== 'referrer'">
                  <icon
                    class="icon"
                    data="@/assets/icons/swap_vertical.svg?icon"
                  />
                </template>
                <span v-else class="gt-inline-flex">
                  <icon
                    class="icon"
                    style="color: #1565c0"
                    data="@/assets/icons/swap_vertical_no_fill.svg?icon"
                  />
                  <span style="color: #1565c0">Switch Profiles</span>
                </span>
              </nuxt-link>
            </slot>
          </span>
        </slot>
      </div>
      <div v-else class="mobile-topnav">
        <icon
          ref="toggle-button"
          class="icon bars"
          data="@/assets/icons/bars.svg?icon"
          @click="toggleSideNav"
        />
        <icon
          class="icon logo"
          data="@/assets/icons/logo.svg?icon"
          original
        />
        <!-- <icon
          v-if="canSearch && user.userType !== 'referrer'"
          class="icon search"
          data="@/assets/icons/microscope.svg?icon"
          original
        /> -->
        <button v-if="user.profileImageUrl" class="profile">
          <slot name="display-image">
            <img :src="user.profileImageUrl" alt="" />
          </slot>
        </button>
      </div>
      <div class="content-wrapper" :class="user.userType">
        <div
          v-if="showSubMenuNav"
          ref="submennav"
          class="sub-menu-nav"
        >
          <p class="title">{{ subMenuData.name }}</p>
          <div class="menu">
            <template
              v-if="subMenuData.list && subMenuData.list.length"
            >
              <div
                v-for="subMenu in subMenuData.list"
                :key="subMenu.name"
                class="sub-menu-nav-links--wrapper"
              >
                <nuxt-link
                  class="item"
                  active-class="active"
                  :class="{ active: subMenu.isActive }"
                  :to="subMenu.type === 'link' ? subMenu.url : ''"
                >
                  <span class="name">{{ subMenu.name }}</span>
                </nuxt-link>
              </div>
            </template>
          </div>
        </div>
        <div
          class="content"
          :class="{ 'has-inner-side-nav': showSubMenuNav }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  PropType,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useGrid } from 'vue-screen';
import { onClickOutside, templateRef } from '@vueuse/core';
import { debounce, get, pick } from 'lodash-es';
import ucwords from 'locutus/php/strings/ucwords';
import { SideNavMenuType } from '@/types/logged';

const props = defineProps({
  menus: {
    type: Array as PropType<SideNavMenuType[]>,
    required: false,
    default: () => [],
  },
  activeMenu: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  search: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  displayImage: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  searchPlaceholder: {
    type: String as PropType<string>,
    required: false,
    default: 'Search here...',
  },
  walletBalance: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits<{
  (event: 'update:search', searchValue: string): unknown;
  (event: 'search-change', searchValue: string): unknown;
  (event: 'menu-click'): unknown;
  (
    event: 'get-viewport-dimensions',
    viewPortWidth: string,
    contentContainerWidth: string,
    contentWidth: string,
  ): unknown;
}>();

const viewportDimensions = ref({ width: 0, height: 0 });
const sideNavDimensions = ref({ width: 0, height: 0 });
const subMenuNavDimensions = ref({ width: 0, height: 0 });

const contentContainerWidth = computed(() => {
  if (viewportDimensions.value.width === 0 || !grid.lg) return '100%';

  return `${
    viewportDimensions.value.width -
    sideNavDimensions.value.width -
    40
  }px`;
});

const contentWidth = computed(() => {
  if (viewportDimensions.value.width === 0 || !grid.lg) return '100%';
  return `${
    viewportDimensions.value.width -
    sideNavDimensions.value.width -
    subMenuNavDimensions.value.width -
    80
  }px`;
});

const viewPortWidth = computed(() => {
  if (viewportDimensions.value.width === 0 || !grid.lg) return '100%';
  return `${viewportDimensions.value.width}px`;
});

watchEffect(() => {
  emit(
    'get-viewport-dimensions',
    `${viewportDimensions.value.width}px`,
    contentContainerWidth.value,
    contentWidth.value,
  );
});

defineExpose({
  contentContainerWidth,
  contentWidth,
  viewPortWidth,
});

const router = useRouter();
const user = useUser();
const communityMessage = useCommunityMessage();

await user.isModuleReady();
await communityMessage.isModuleReady();

const searchFocused = ref<boolean>(false);
const localSearchRef = ref(props.search);
const handleSearch = () => {
  if (localSearchRef.value || props.search || '') {
    return emit(
      'update:search',
      localSearchRef.value || props.search || '',
    );
  }
};

const isPageReady = ref(false);
const animationClasses = computed(() =>
  isPageReady.value
    ? ['animate__animated', 'animate__fadeIn', 'animate__slow']
    : [],
);

const sideNavAnimationClasses = computed(() =>
  isPageReady.value
    ? ['animate__animated', 'animate__slideInLeft', 'animate__slow']
    : [],
);

onMounted(async () => {
  document.body.classList.add('noscroll');

  await nextTick();
  setTimeout(() => {
    isPageReady.value = true;
  }, 1500);
});

onUnmounted(() => {
  document.body.classList.remove('noscroll');
});

const logout = () => user.logout();

const grid = useGrid(user.breakpoints);

const showSideNav = computed(() =>
  !grid.lg ? user.showSideNav : true,
);

const toggleSideNav = () => {
  user.setState({
    showSideNav: !showSideNav.value,
  });
};

const sidenavEl = templateRef<HTMLElement>('sidenav');
const subMenuNavEl = templateRef<HTMLElement>('submennav');
const showSideNavToggle = templateRef<HTMLElement>('toggle-button');

onClickOutside(sidenavEl, (e) => {
  if (!grid.lg) {
    const target = get(e, 'target', undefined) as Node | undefined;
    const button = get(
      showSideNavToggle,
      'value.$el',
      null,
    ) as HTMLElement | null;

    if (button && target) {
      if (button === target || button.contains(target)) return;
    }

    user.setState({
      showSideNav: false,
    });
  }
});

const activeMenuData = computed(() => {
  if (props.activeMenu) {
    const activeMenu = props.activeMenu.toLowerCase();

    const menu = props.menus.find(({ name }) => {
      return name.toLowerCase() === activeMenu;
    });

    if (menu) {
      return menu;
    }
  }

  return undefined;
});

const showSubMenuNav = computed(() => {
  if (activeMenuData.value) {
    return (
      activeMenuData.value?.isActive &&
      activeMenuData.value?.canIsolateSubMenu &&
      activeMenuData.value.subMenu &&
      activeMenuData.value.subMenu.length > 0 &&
      grid.lg
    );
  }

  return false;
});

const showSubMenuInline = computed(() => {
  if (activeMenuData.value) {
    return (
      activeMenuData.value?.isActive &&
      activeMenuData.value.subMenu &&
      activeMenuData.value.subMenu?.length > 0 &&
      (!grid.lg || !activeMenuData.value?.canIsolateSubMenu)
    );
  }

  return false;
});

const isInlineSubMenuFor = computed(() => (name: string) => {
  name = String(name).toLowerCase();
  if (showSubMenuInline.value) {
    return name === activeMenuData.value?.name.toLowerCase();
  }

  return false;
});

const subMenuData = computed(() => ({
  name: ucwords(activeMenuData.value?.name),
  list: activeMenuData.value?.subMenu || [],
}));

const closeNavigation = (event: Event) => {
  if (!grid.lg) {
    const target = event.target as Element | undefined;

    if (target && target.closest('.link-item')) {
      user.setState({
        showSideNav: false,
      });
    }
  }
};

const handleClickOnSubMenuParent = (
  event: Event,
  menu: SideNavMenuType,
) => {
  if (!menu.isSubMenuExpanded) {
    event.stopImmediatePropagation();
  }

  return menu?.toggleExpandSubMenu?.();
};

const computeSideNavDimensions = debounce(
  () => {
    if (grid.lg && sidenavEl.value) {
      const rect = sidenavEl.value?.getBoundingClientRect();

      if (rect) {
        sideNavDimensions.value = pick(rect, ['width', 'height']);
      }
    } else {
      sideNavDimensions.value = {
        width: 0,
        height: 0,
      };
    }
  },
  200,
  {
    maxWait: 500,
    trailing: true,
    leading: false,
  },
);

const computeSubMenuNavDimensions = debounce(
  () => {
    if (showSubMenuNav.value && subMenuNavEl.value) {
      const rect = subMenuNavEl.value?.getBoundingClientRect();

      if (rect) {
        subMenuNavDimensions.value = pick(rect, ['width', 'height']);
      }
    } else {
      subMenuNavDimensions.value = {
        width: 0,
        height: 0,
      };
    }
  },
  200,
  {
    maxWait: 500,
    trailing: true,
    leading: false,
  },
);

const computViewPortDimensions = debounce(
  () => {
    viewportDimensions.value.height = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    viewportDimensions.value.width = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
  },
  200,
  {
    maxWait: 500,
    trailing: true,
    leading: false,
  },
);

const computeViewPortDimensionsForReferrer = debounce(
  () => {
    viewportDimensions.value.height =
      (document.documentElement.clientHeight ?? 0) ||
      (window.innerHeight ?? 0);
    viewportDimensions.value.width =
      (document.documentElement.clientWidth ?? 0) ||
      (window.innerWidth ?? 0);
  },
  200,
  {
    maxWait: 500,
    trailing: true,
    leading: false,
  },
);

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (entry.target === document.body) {
      if (user.userType === 'referrer')
        computeViewPortDimensionsForReferrer();
      else computViewPortDimensions();
    }

    if (entry.target === sidenavEl.value) {
      computeSideNavDimensions();
    }

    if (entry.target === subMenuNavEl.value) {
      computeSubMenuNavDimensions();
    }
  }
});

onMounted(() => {
  computeSideNavDimensions();
  computeSubMenuNavDimensions();
});

resizeObserver.observe(document.body);
onBeforeUnmount(() => {
  resizeObserver.unobserve(document.body);
});

watch(
  sidenavEl,
  (el, prev) => {
    if (el) {
      resizeObserver.observe(el);
    } else if (prev) {
      resizeObserver.unobserve(prev);
    }

    computeSideNavDimensions();
  },
  {
    immediate: true,
  },
);

watch(
  subMenuNavEl,
  (el, prev) => {
    if (el) {
      resizeObserver.observe(el);
    } else if (prev) {
      resizeObserver.unobserve(prev);
    }

    computeSubMenuNavDimensions();
  },
  {
    immediate: true,
  },
);

const refreshKey = ref(Date.now());
watch(
  () => user.token,
  (curr, prev) => {
    if (curr && curr !== prev) {
      refreshKey.value = Date.now();
    }
  },
  {
    immediate: true,
  },
);

const unreadCommunityMessagingCount = computed(() =>
  communityMessage.conversationWindows.reduce((prev, window) => {
    if (!window.chat.isRead && window.unreadCount) {
      return prev + (window.unreadCount || 0);
    }

    return prev;
  }, 0),
);

const showCommunityMessaging = computed(() => {
  return (
    user.isLogged &&
    user.hasProfile &&
    (user.userType === 'candidate' ||
      user.userType === 'company-user') &&
    router &&
    router.currentRoute &&
    router.currentRoute.value.path !== '/messages'
  );
});

const toggleCommunityMessagingDisplay = async () => {
  await navigateTo('/messages');

  if (showCommunityMessaging.value) {
    communityMessage.user.setState({
      communityMessageState: 'minimized',
    });
  } else {
    communityMessage.user.setState({
      communityMessageState: 'maximized',
    });
  }
};

const getChangeEventValue = (event: Event): string => {
  return get(event, 'target.value', '') as string;
};

const canSearch = computed(
  () =>
    !['/messages', '/message'].some((path) =>
      String(
        (router &&
          router.currentRoute &&
          router.currentRoute.value.path) ||
          '',
      ).startsWith(path),
    ),
);
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;
@use '@/assets/scss/_animations.scss' as animation;
@use 'sass:color' as sasscolor;

.component.entry.framesdefault {
  .loader {
    height: 100vh;
    width: 100%;
  }

  .animate__animated.animate__fadeIn {
    animation-duration: 2s !important;
    transition-duration: 2s !important;
  }

  :deep() {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    position: relative;
    background-color: sasscolor.adjust(
      color.$white-1,
      $lightness: -5%
    );

    > .sidenav {
      width: auto;
      display: none;
      flex-direction: column;
      align-items: center;
      // padding: 20px 20px 0;
      min-height: 100vh;
      background-color: color.$white-1;
      transition: all 0.3s ease-in-out;

      &.absolute {
        position: fixed;
        left: 0;
        top: 0;
        width: auto;
        max-width: 70%;
        height: 100%;
        z-index: 2000;
        box-shadow: 2px 2px 8px 0 color.$ash-1;
      }

      &.show {
        display: flex;
      }

      > * {
        display: flex;
        // width: 180px;
        width: 220px;
        flex-wrap: nowrap;
      }

      > .logo {
        padding: 20px 20px 0 20px;
        height: auto;

        > .icon {
          width: 100%;
          height: auto;
        }
      }

      > .menu {
        margin: 40px 0 0 0;
        flex-direction: column;
        height: calc(100vh - 110px);
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 60px;
        padding: 0 20px 60px 20px;

        > .title {
          font-size: 14px;
          text-transform: uppercase;
          color: color.$brown-2;
          margin: 0 0 10px 0;
        }

        > .menu-item {
          display: flex;
          width: 100%;
          height: auto;
          flex-direction: column;

          > .sub-menu {
            margin-top: 10px;
            padding: 0 0 0 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
          }
        }

        > .menu-item > .link-item,
        > .link-item,
        > .menu-item > .sub-menu > .link-item {
          width: 100%;
          padding: 12px 15px;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.3s ease-in-out;
          margin: 10px 0 0 0;
          text-decoration: initial;
          border: initial;
          outline: initial;
          cursor: pointer;

          > * {
            display: inline-flex;
            color: #1565c0;
            transition: all 0.3s ease-in-out;
          }

          > .icon {
            width: 20px;
            height: 20px;
          }

          > .name {
            margin: 0 0 0 20px;
            overflow-x: hidden;
            flex-wrap: nowrap;
            font-size: 14px;
            text-transform: capitalize;
          }

          &:hover:not(.logout),
          &.active {
            background-color: #1565c0;

            > * {
              color: color.$white-1;
            }
          }

          &.toggle-messenger {
            background-color: initial;
          }

          &.logout {
            margin: 50px 0 0 0;

            > * {
              color: #1565c0;
            }
          }
        }
        &.referrer {
          > .menu-item > .link-item,
          > .link-item,
          > .menu-item > .sub-menu > .link-item {
            > * {
              color: #546e7a;
              font-weight: 500;
              font-size: 16px;
              line-height: 1.3;
              letter-spacing: 0.1px;
            }
            &:hover,
            &.active {
              background-color: #1565c0;

              > * {
                color: color.$white-1;
              }
            }
          }
        }
      }
      &.referrer {
        box-shadow:
          0px 0px 1px rgba(66, 71, 76, 0.32),
          0px 4px 8px rgba(66, 71, 76, 0.06),
          0px 8px 48px #eeeeee;
        border-right: solid 1px #eeeeee;
      }
    }

    > .content-container {
      display: flex;
      flex-direction: column;
      width: v-bind(contentContainerWidth);
      height: auto;
      margin: 0;
      padding: 0 20px;
      align-items: center;
      align-content: flex-start;
      background-color: #f3f3f3;

      @include breakpoint.respond-below(lg) {
        padding: 0 10px;
      }

      @include breakpoint.respond-below(sm) {
        padding: 0;
      }

      &.mobile {
        max-width: v-bind(viewPortWidth);
      }

      > .topnav {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        column-gap: 20px;

        > * {
          display: inline-flex;
          align-items: center;
        }

        > .icon.bars {
          width: 22px;
          height: 22px;
          transform: scaleY(1.3);
          margin: 0 20px 0 0;
          cursor: pointer;
        }

        > .search {
          margin: 0 auto;

          @include breakpoint.respond-below(lg) {
            flex-grow: 1;
          }

          > .group {
            display: inline-flex;

            > .input-icon {
              display: inline-flex;
              min-width: 500px;
              border: none;
              position: relative;
              align-items: center;
              margin: 0;

              @include breakpoint.respond-below(lg) {
                min-width: initial;
              }

              > input {
                width: 100%;
                height: 33px;
                padding: 0 0 0 30px;
                font-size: 13px;
                color: color.$ash-1;
                border: initial;
                border-radius: 10px;
                transition: all 0.3s ease-in-out;
                outline: initial;
                background-color: color.$white-1;

                &:focus {
                  box-shadow: 1px 1px 5px 0 rgba(color.$ash-1, 0.4);
                }
              }

              > .icon {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 30px;
                color: color.$ash-2;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
              }
            }
          }
        }

        > .actions {
          display: inline-flex;
          margin: 0 0 0 auto;
          justify-content: flex-start;
          align-items: stretch;
          flex-wrap: nowrap;

          > * {
            display: inline-flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: color.$light-brown-2;
            border: initial;
            outline: initial;
            cursor: pointer;
            text-decoration: initial;
            margin: 0;
            position: relative;

            > .icon {
              width: 15px;
              height: 15px;
              color: color.$black-1;
              transform: scale(0.9);
            }

            &:not(:first-child) {
              margin: 0 0 0 18px;
            }
          }
          button.referrer {
            background-color: #eeeeee;
          }

          > .messages {
            z-index: 2;

            &::before {
              @include animation.pulse-animation(
                $color: color.$brown-3
              );

              position: absolute;
              content: '';
              z-index: 1;
              display: flex;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              transition: all 0.3s ease-in-out;
              background-color: transparent;
            }

            &.hidden {
              display: none;
            }

            @keyframes change-background-color-for-chat-window-header {
              from {
                background-color: initial;
              }
              to {
                background-color: color.$light-brown-2;
              }
            }

            &.has-unread {
              animation-name: change-background-color-for-chat-window-header;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-direction: alternate;
            }

            > .unread-count {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: color.$black-1;
              color: color.$white-1;
              position: absolute;
              right: -8px;
              top: -8px;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: 'LatoBold', 'LatoRegular', Tahoma, Geneva,
                sans-serif;
              font-size: 8px;
              box-shadow: 1px 1px 4px 0 color.$ash-3;
            }
          }

          > .display-image {
            background-color: transparent;
            cursor: initial;

            > img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }

          > .switch-account {
            background-color: transparent;
            margin: 0;

            > .icon {
              color: color.$brown-2;
              width: 20px;
              height: 20px;
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              > .icon {
                transform: scale(1.3);
                color: color.$brown-1;
              }
            }
          }
        }
        &.referrer {
          padding-left: 25px;
          padding-right: 25px;
          > .wallet-preview {
            margin-left: auto;
            column-gap: 10px;
            font-weight: 700;
            color: #281103;
            display: initial;
            a {
              text-decoration: none;
            }
          }
          > .search {
            margin: 0;
            @include breakpoint.respond-below(lg) {
              flex-grow: 0;
            }
            > .group {
              > .input-icon {
                input {
                  box-shadow: 1px 1px 5px 0 rgba(color.$ash-1, 0.4);
                }
                min-width: fit-content;
                &.focus {
                  min-width: 300px;
                }
              }
            }
          }
          > .actions {
            column-gap: 20px;
            margin-left: 0;
            .switch-account {
              width: fit-content;
              span {
                column-gap: 20px;
                align-items: center;
              }
              svg {
                width: 24px;
                height: 24px;
                transform: rotate(90deg);
              }
            }
          }
        }
      }

      > .mobile-topnav {
        display: flex;
        width: v-bind(viewPortWidth);
        justify-content: flex-start;
        align-items: center;
        background-color: color.$white-1;
        padding: 10px 20px;
        margin: 0 0 5px 0;
        box-shadow: 2px 2px 8px 0 color.$ash-1;

        > .icon.bars {
          width: 25px;
          height: 25px;
          transform: scaleY(1.3);
        }

        > .icon.logo {
          margin: 0 auto;
          width: 180px;
          height: 30px;
        }

        > .icon.search {
          width: 25px;
          height: 25px;
          margin: 0;
        }

        > .profile {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: color.$ash-1;
          border: initial;
          outline: initial;
          margin: 0 0 0 15px;
        }
      }

      > .content-wrapper {
        display: flex;
        width: 100%;

        > .sub-menu-nav {
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 20px 0;
          margin: 0 20px 0 0;
          min-height: 100vh;
          background-color: color.$white-1;
          z-index: 1;
          transition: all 0.3s ease-in-out;

          > * {
            display: flex;
            width: 180px;
            flex-wrap: nowrap;
          }

          > .title {
            font-size: 14px;
            text-transform: uppercase;
            color: color.$brown-2;
            margin: 0 0 20px 0;
          }

          > .menu {
            margin: 10px 0 0 0;
            flex-direction: column;
            height: calc(100vh - 110px);
            overflow: hidden;
            overflow-y: auto;

            .sub-menu-nav-links--wrapper {
              border-bottom: 0.3px solid #c85310;

              > .item {
                width: 100%;
                padding: 0px 15px;
                // margin: 10px 0;
                // border-radius: 8px;
                display: flex;
                // gap: 10px;
                justify-content: flex-start;
                align-items: center;
                transition: all 0.3s ease-in-out;
                // margin: 10px 0 0 0;
                text-decoration: initial;
                border: initial;
                outline: initial;
                cursor: pointer;
                border-left: 3px solid transparent;
                margin: 10px 0px;

                > * {
                  display: inline-flex;
                  color: color.$brown-2;
                  transition: all 0.3s linear;
                }

                > .icon {
                  width: 20px;
                  height: 20px;
                }

                > .name {
                  // margin: 0 0 0 20px;
                  overflow-x: hidden;
                  flex-wrap: nowrap;
                  font-size: 14px;
                  text-transform: capitalize;
                }

                // &:hover,
                &.active {
                  // background-color: #c85310;
                  border-left: 3px solid #1565c0;
                  // background-color: color.$brown-2;
                }
              }
            }
          }
        }

        > .content {
          display: flex;
          width: v-bind(contentWidth);
          margin: 0;
          // box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.1);
          max-width: viewport.$max-width;
          @include breakpoint.respond-above(lg) {
            margin: 0 auto;
          }

          &.has-inner-side-nav {
            max-width: viewport.$box-extra-large-width;
          }
        }
      }
      &.referrer {
        min-height: 100vh;
        .content {
          @include breakpoint.respond-below(md) {
            max-width: 100%;
          }
        }
        > .content-wrapper {
          margin-top: 80px;
          padding-left: 13px;
          padding-right: 35px;
          @include breakpoint.respond-below(lg) {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
