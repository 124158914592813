<template>
  <div class="layouts entry default">
    <div
      v-if="needsFrame"
      class="framed animate__animated animate__fadeIn"
    >
      <component :is="Frame">
        <suspense>
          <template #default>
            <LazyNuxtPage />
          </template>
          <template #fallback>
            <Loader class="loader" size="large" />
          </template>
        </suspense>
      </component>
    </div>
    <div v-else class="unframed animate__animated animate__fadeIn">
      <suspense>
        <template #default>
          <LazyNuxtPage />
        </template>
        <template #fallback>
          <Loader class="loader" size="large" />
        </template>
      </suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: 'GoodTalent | %s',
  title: 'Marketplace for Engineering Talent',
});

const route = useRoute();
const user = useUser();
await user.isModuleReady();

const isLogged = computed(() => user.isLogged);

const authPages = [
  'login',
  'register',
  'forgot-password',
  'vendor-register',
];

const isAuthPage = computed(() => {
  if (route && route.name && typeof route.name === 'string') {
    return authPages.includes(route.name);
  }

  return false;
});

const mayNeedFramePages = [
  'index',
  'jobs',
  'job-jobTitle--jobUniqueId',
  'job-jobTitle--jobUniqueId-index',
  'jobs-jobTitle--jobUniqueId',
  'jobs-jobTitle--jobUniqueId-index',
  'acceptable-use',
  'privacy',
  'terms',
];

const routeMayNeedFrame = computed(() => {
  if (route && route.name && typeof route.name === 'string') {
    return mayNeedFramePages.includes(route.name);
  }

  return false;
});

const needFramePages = ['dashboard', 'referral-dashboard'];
const routeNeedFrame = computed(() => {
  if (route && route.name && typeof route.name === 'string') {
    return needFramePages.includes(route.name);
  }

  return false;
});

const doesntNeedFramePages = [
  'company-create',
  'profile-talent-create',
];
const routeDoesntNeedFrame = computed(() => {
  if (route && route.name && typeof route.name === 'string') {
    return doesntNeedFramePages.includes(route.name);
  }

  return false;
});

const Frame = computed(() => {
  if (isAuthPage.value) return resolveComponent('FramesAuthUnlogged');

  const userType = user.userType;
  if (userType) {
    if (userType === 'candidate')
      return resolveComponent('FramesCandidate');
    if (userType === 'company-user')
      return resolveComponent('FramesCompany');
    if (userType === 'referrer')
      return resolveComponent('FramesReferral');
  }

  return resolveComponent('FramesUnlogged');
});

const needsFrame = computed(() => {
  if (isAuthPage.value) return true;
  if (routeDoesntNeedFrame.value) return false;
  if (Frame.value && routeMayNeedFrame.value && isLogged.value)
    return true;
  if (routeNeedFrame.value && !!Frame.value) return true;
  return !!Frame.value /* && routeNeedFrame.value */;
});

console.log('Frame', Frame.value);
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_colors.scss' as color;

.layouts.entry.default {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: color.$white-1;

  > .framed,
  > .unframed {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    align-content: flex-start;
    justify-content: center;

    &.animate__animated.animate__fadeIn {
      animation-duration: 2s !important;
      transition-duration: 2s !important;
    }
  }

  .loader {
    height: 100vh;
    width: 100%;
  }
}
</style>
