<template>
  <DefaultFrame
    :key="filteredMenuKey"
    :search="globalSearch"
    :menus="filteredMenus"
    :active-menu="activeMenu"
    :display-image="displayImage"
    search-placeholder="Search..."
    v-bind="$attrs"
    @update:search="updateGlobalSearch"
  >
    <template #default>
      <Suspense>
        <template #default>
          <GlobalSearchComponent
            v-if="showGlobalSearchComponent"
            :search="globalSearch"
            @close="closeGlobalSearch"
          />
          <Suspense v-else>
            <template #default>
              <slot></slot>
            </template>
            <template #fallback>
              <Loader class="entry componentframescompany loader" />
            </template>
          </Suspense>
        </template>
        <template #fallback>
          <Loader class="entry componentframescompany loader" />
        </template>
      </Suspense>
    </template>

    <template v-for="name in otherSlots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </DefaultFrame>
</template>

<script setup lang="ts">
import {
  isString,
  isArray,
  isEmpty,
  isEqual,
  get,
  keys,
  without,
} from 'lodash-es';
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  watch,
} from 'vue';
import * as svgIcon from '@yzfe/svgicon';
import DefaultFrame from './Default.vue';
import { CompanyProfilePermissionType } from '@/types/permission';
import { SideNavMenuType } from '@/types/logged';

const slots = useSlots();
const displayImage = ref('');
const route = useRoute();
const router = useRouter();
const user = useUser();
await user.isModuleReady();

const GlobalSearchComponent = defineAsyncComponent({
  loader: () => import('@/components/globalsearch/GlobalSearch.vue'),
});

const isShowingSubMenuFor = computed(
  () =>
    (name: string): boolean => {
      return activeMenu.value === name.toLowerCase();
    },
);

const expandedSubMenus = ref<string[]>([]);

const activeMenu = computed(() => {
  const menu = filteredMenus.value.find(
    ({ subMenu, isActive, name }) => {
      if (isActive) {
        if (subMenu && subMenu.length) {
          return !!subMenu.find((subMenuMenu) => {
            if (subMenuMenu.type === 'link') {
              return (
                (router &&
                  router.resolve(subMenuMenu.url).name ===
                    route.name) ||
                subMenuMenu.isActive
              );
            }

            if (subMenuMenu.type === 'button') {
              return subMenuMenu.isActive;
            }

            return false;
          });
        }

        return true;
      }

      return false;
    },
  );

  if (menu) {
    return menu.name.toLowerCase();
  }

  return '';
});

const filteredMenuKey = ref(Date.now());
const filteredMenus = computed(() => {
  if (!filteredMenuKey.value) {
    return [];
  }

  const validMenus: SideNavMenuType[] = [];
  const menus = ref<SideNavMenuType[]>([
    {
      name: 'Dashboard',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/dashboard.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/dashboard' },
    },
    {
      name: 'Job Openings',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/job.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/jobs' },
      isActive: computed(() =>
        [
          'job-jobTitle--jobUniqueId',
          'job-jobTitle--jobUniqueId-index',
          'job-edit',
          'job',
          'job-create',
          'job-jobTitle--jobUniqueId-application',
        ].includes(String(route.name)),
      ),
    },
    {
      name: 'Talents',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/accounts.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/talents' },
      isActive: computed(() =>
        ['talents', 'company-talent-create'].includes(
          String(route.name),
        ),
      ),
    },
    {
      name: 'Assessments',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/histogram.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/assessments' },
    },
    {
      name: 'Interviews',
      icon: computedAsync(async () => {
        const svg = await import(
          '@/assets/icons/conversation.svg?icon'
        );

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/interviews' },
      isActive: computed(() => {
        const routeName = String(route.name);

        return (
          routeName.startsWith('interviews-') ||
          routeName === 'interviews'
        );
      }),
    },
    {
      name: 'Emails',
      icon: computedAsync(async () => {
        const svg = await import(
          '@/assets/svgs/icons/emails.svg?icon'
        );

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { name: 'custom-messages' },
      isActive: computed(
        () =>
          String(route.name).startsWith('custom-messages-') ||
          String(route.name) === 'custom-messages',
      ),
    },
    {
      name: 'Community',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/gt-icon.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/community' },
      isActive: computed(() => {
        const routeName = String(route.name);
        return (
          routeName === 'community' ||
          routeName.startsWith('community-')
        );
      }),
    },
    {
      name: 'Finance',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/wallet.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/finance/wallet' },
      isActive: computed(() => {
        const routeName = String(route.name || '');
        return (
          routeName.startsWith('finance-') || routeName === 'finance'
        );
      }),
      isSubMenuExpanded: computed(() => {
        const routeName = String(route.name || '');
        const isAnySubMenuActive =
          expandedSubMenus.value.includes('finance') ||
          routeName.startsWith('finance-');

        return isAnySubMenuActive;
      }),
      canIsolateSubMenu: ref(false),
      toggleExpandSubMenu: () => {
        const index = expandedSubMenus.value.findIndex(
          (name) => name === 'finance',
        );

        if (index > -1) {
          expandedSubMenus.value.splice(index, 1);
        } else {
          expandedSubMenus.value.push('finance');
        }
      },
      subMenu: [
        {
          name: 'Subscriptions',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import(
              '@/assets/icons/txn-recurring.svg?icon'
            );

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: { path: '/finance/subscriptions' }, // finance addons
          isActive: computed(
            () =>
              String(route.name).startsWith(
                'finance-subscriptions-',
              ) || String(route.name) === 'finance-subscription',
          ),
        },
        {
          name: 'Wallet',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import(
              '@/assets/icons/wallet.svg?icon'
            );

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: { path: '/finance/wallet' },
          isActive: computed(() =>
            ['finance-wallet'].includes(String(route.name)),
          ),
        },
        {
          name: 'Addons',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import('@/assets/icons/plus.svg?icon');

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: { path: '/finance/addons' }, // finance addons
          isActive: computed(
            () =>
              String(route.name).startsWith('finance-addons-') ||
              String(route.name) === 'finance-addons',
          ),
        },
      ],
    },
    {
      name: 'Pricing Plans',
      type: 'link',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/bars.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      url: { path: '/pricing-plans' },
      isActive: computed(() =>
        ['pricing-plans'].includes(String(route.name)),
      ),
    },
    {
      name: 'Account',
      icon: computedAsync(async () => {
        const svg = await import('@/assets/icons/account.svg?icon');

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { name: 'company' },
      isActive: computed(() => {
        const routeName = String(route.name);

        return (
          isShowingSubMenuFor.value('account') ||
          routeName === 'company' ||
          routeName.startsWith('company-') ||
          routeName.startsWith('profile-')
        );
      }),
      isSubMenuExpanded: computed(() => {
        const routeName = String(route.name);

        return (
          isShowingSubMenuFor.value('account') ||
          routeName.startsWith('company-') ||
          routeName.startsWith('profile-')
        );
      }),
      canIsolateSubMenu: ref(true),
      toggleExpandSubMenu: () => {
        const index = expandedSubMenus.value.findIndex(
          (name) => name === 'account',
        );

        if (index > -1) {
          expandedSubMenus.value.splice(index, 1);
        } else {
          expandedSubMenus.value.push('account');
        }
      },
      subMenu: [
        {
          name: 'Departments',
          type: 'link',
          url: { name: 'company-departments' },
          isActive: computed(() =>
            String(route.name).startsWith('company-departments'),
          ),
        },
        {
          name: 'Users',
          type: 'link',
          url: { name: 'company-users' },
          isActive: computed(() =>
            String(route.name).startsWith('company-users'),
          ),
        },
        // {
        //   name: 'Subscriptions',
        //   type: 'link',
        //   url: { name: 'company.subscription' },
        // },
        {
          name: 'Company Profile',
          type: 'link',
          url: { name: 'company' },
          isActive: computed(() => {
            const routeName = String(route.name);
            return routeName === 'company';
          }),
        },
        {
          name: 'User Profile',
          type: 'link',
          url: { name: 'profile-view' },
          isActive: computed(() =>
            ['profile-view', 'profile-edit'].includes(
              String(route.name),
            ),
          ),
        },
        {
          name: 'Preferences',
          type: 'link',
          url: { name: 'company-settings' },
        },
      ],
    },
    {
      name: 'Admin Area',
      icon: computedAsync(async () => {
        const svg = await import(
          '@/assets/svgs/icons/admin-user.svg?icon'
        );

        if (svg) {
          return get(svg, 'default', '') as svgIcon.Icon;
        }

        return '';
      }, ''),
      type: 'link',
      url: { path: '/admin' },
      isActive: computed(() => {
        const routeName = String(route.name || '');
        return (
          routeName.startsWith('admin-') || routeName === 'admin'
        );
      }),
      isSubMenuExpanded: computed(() => {
        const routeName = String(route.name || '');
        const isAnySubMenuActive =
          expandedSubMenus.value.includes('admin') ||
          routeName.startsWith('admin-') ||
          routeName === 'admin';

        return isAnySubMenuActive;
      }),
      canIsolateSubMenu: ref(false),
      toggleExpandSubMenu: () => {
        const index = expandedSubMenus.value.findIndex(
          (name) => name === 'admin',
        );

        if (index > -1) {
          expandedSubMenus.value.splice(index, 1);
        } else {
          expandedSubMenus.value.push('admin');
        }
      },
      subMenu: [
        {
          name: 'Payments',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import(
              '@/assets/icons/payments.svg?icon'
            );

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: { name: 'admin-payments' }, // Finance payments
          isActive: computed(() => {
            const routeName = String(route.name);
            return (
              ['admin-payments'].includes(routeName) ||
              routeName.startsWith('admin-payments-')
            );
          }),
        },
        {
          name: 'Pricing',
          type: 'link',
          icon: computedAsync(async () => {
            const svg = await import('@/assets/icons/bars.svg?icon');

            if (svg) {
              return get(svg, 'default', '') as svgIcon.Icon;
            }

            return '';
          }, ''),
          url: { name: 'admin-pricing' },
          isActive: computed(() => {
            const routeName = String(route.name);

            return (
              routeName.startsWith('admin-pricing-') ||
              routeName === 'admin-pricing'
            );
          }),
        },
      ],
    },
  ]);

  for (const menu of menus.value) {
    const menuName = menu.name.toLowerCase();
    switch (true) {
      case menuName === 'dashboard': {
        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.DASHBOARD_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'job openings': {
        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.JOB_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'talents': {
        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.TALENT_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'assessments': {
        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.ASSESSMENT_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'interviews': {
        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.INTERVIEW_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'community': {
        let isValid = false;

        if (user.userType === 'candidate') {
          isValid = true;
        } else if (user.userType === 'company-user') {
          isValid = user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.SOCIAL_GENERAL],
            canView: [CompanyProfilePermissionType.SOCIAL_GENERAL],
          });
        }

        if (isValid) {
          validMenus.push(menu);
        }

        break;
      }

      case menuName === 'finance': {
        const subMenu = menu.subMenu;

        if (subMenu && isArray(subMenu)) {
          const validSubMenu = subMenu.filter(
            ({ name: subMenuName }) => {
              subMenuName = subMenuName.toLowerCase();

              switch (true) {
                case ['subscriptions', 'addons'].includes(
                  subMenuName,
                ): {
                  return !user.isSuperAdmin;
                }
              }

              return true;
            },
          );

          menu.subMenu = validSubMenu;
        }

        if (
          user.hasPermissionTo({
            canSee: [CompanyProfilePermissionType.WALLET_GENERAL],
            canView: [CompanyProfilePermissionType.WALLET_GENERAL],
          })
        ) {
          validMenus.push(menu);
        }
        break;
      }

      case menuName === 'pricing plans': {
        if (user.userType === 'company-user') {
          if (
            user.hasPermissionTo({
              canSee: [CompanyProfilePermissionType.WALLET_GENERAL],
              canView: [CompanyProfilePermissionType.WALLET_GENERAL],
            }) &&
            !user.isSuperAdmin
          ) {
            validMenus.push(menu);
          }
        }
        break;
      }

      case menuName === 'account': {
        const subMenu = menu.subMenu;

        if (subMenu && isArray(subMenu)) {
          const validSubMenu = subMenu.filter(
            ({ name: subMenuName }) => {
              subMenuName = subMenuName.toLowerCase();

              switch (true) {
                case subMenuName === 'departments': {
                  return user.hasPermissionTo({
                    canSee: [
                      CompanyProfilePermissionType.ACCOUNT_DEPARTMENT_GENERAL,
                    ],
                  });
                }

                case subMenuName === 'users': {
                  return user.hasPermissionTo({
                    canSee: [
                      CompanyProfilePermissionType.ACCOUNT_USER_GENERAL,
                    ],
                  });
                }

                case subMenuName === 'custom messages': {
                  return user.hasPermissionTo({
                    canSee: [
                      CompanyProfilePermissionType.ACCOUNT_CUSTOM_MESSAGE_GENERAL,
                    ],
                  });
                }

                case subMenuName === 'company profile': {
                  return user.hasPermissionTo({
                    canSee: [
                      CompanyProfilePermissionType.ACCOUNT_COMPANY_PROFILE_GENERAL,
                    ],
                  });
                }

                case subMenuName === 'preferences': {
                  return user.hasPermissionTo({
                    canSee: [
                      CompanyProfilePermissionType.ACCOUNT_COMPANY_SETTING_GENERAL,
                    ],
                  });
                }
              }

              return true;
            },
          );

          menu.subMenu = validSubMenu;
        }

        validMenus.push(menu);
        break;
      }

      case menuName === 'admin area': {
        if (user.isSuperAdmin) {
          validMenus.push(menu);
        }
        break;
      }

      default:
        validMenus.push(menu);
        break;
    }
  }

  return validMenus;
});

watch(
  () => user.profileData,
  (profileData, prev) => {
    if (
      profileData &&
      !isEmpty(profileData) &&
      !isEqual(profileData, prev)
    ) {
      filteredMenuKey.value = Date.now();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

const globalSearch = ref('');
const showGlobalSearchComponent = ref(false);

const closeGlobalSearch = () => {
  showGlobalSearchComponent.value = false;
};

watch(
  () => router && router.currentRoute && router.currentRoute.value,
  () => {
    closeGlobalSearch();
  },
);

const updateGlobalSearch = (search: string) => {
  if (search && isString(search)) {
    globalSearch.value = search;
    showGlobalSearchComponent.value = true;
  }
};

const otherSlots = computed(() => {
  const list = slots;
  return without(keys(list), 'default') as string[];
});
</script>

<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;
@use 'sass:color' as sasscolor;

.entry.componentframescompany.loader {
  height: calc(100vh - 500px);
  width: 100%;
}
</style>
